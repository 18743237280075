import React, { useState, useRef } from "react";
import { Link } from "gatsby";

// Styles
import styles from "./checking-overview.module.scss";
import Icon from "../custom-widgets/icon";
import "./checking-overview.bootstrap.scss";

import nameToId from "../../helpers/nameToId";

const CheckingOverviewSpanish = (props) => {
  const [collapseClass1, setCollapseClass1] = useState(false);
  const [collapseClass2, setCollapseClass2] = useState(false);

  const [hideSimilar, setHideSimilar] = useState(false);

  const GreenCheckmark = () => <Icon class={`text-success ${styles.checkingTableIcon}`} name="check" lib="far" />;
  const GreenCheckmarkMobile = () => (
    <Icon class={`text-success mt-1 mr-1 ${styles.checkingTableIcon}`} name="check" lib="fas" />
  );
  const MinusIcon = () => <Icon class={`text-secondary ${styles.checkingTableIcon}`} name="minus" lib="fal" />;
  const MinusIconMobile = () => (
    <Icon class={`text-secondary mt-1 mr-1 ${styles.checkingTableIcon}`} name="minus" lib="fas" />
  );

  const CheckingButton = ({ id, collapseVariable, onClick, acctName, isShowText }) => {
    const label = collapseVariable ? "Cerrar" : "Mostrar";
    return (
      <button
        id={id}
        onClick={onClick}
        className={`d-flex align-items-center ${styles.checkingDetails} ${styles.buttonDivider} ${
          collapseVariable ? styles.checkingDetailsOpen : styles.checkingDetailsClosed
        }`}
      >
        <Icon name={collapseVariable ? "minus-circle" : "plus-circle"} class="mr-1" />
        <span>{isShowText ? label : acctName}</span>
      </button>
    );
  };

  const CheckingRow = ({
    title,
    url,
    linkId,
    text,
    textLink,
    indentation,
    mobile,
    checkedArray = [{ checked: false, productName: "" }]
  }) => {
    // tr -> row
    // td -> columns, 1 is content, 1 is greencheckmark
    // desktop -> 4 columns, 1 content, 3 greencheckmarks
    const allSimilar =
      hideSimilar &&
      checkedArray.every((value) => {
        return value.checked === true;
      });
    return (
      <tr className={allSimilar ? `d-none` : `d-table-row`}>
        {/* Content Column */}
        <td className={`${indentation ? "ml-3 d-flex flex-column" : ""}`}>
          {/* Title or link title */}
          {url ? (
            <Link
              to={url}
              id={linkId ? linkId : `${nameToId(title)}-link`}
              className="font-weight-bold"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          ) : (
            <span className="font-weight-bold">{title}</span>
          )}
          {/* Text or link text */}
          {text &&
            (textLink ? (
              <Link
                to={textLink}
                id={`${nameToId(title)}-link`}
                className="font-weight-bold"
                dangerouslySetInnerHTML={{ __html: text }}
              />
            ) : (
              <div className="text-gray-70 text-sm" dangerouslySetInnerHTML={{ __html: text }} />
            ))}
        </td>
        {/* Green Checkmark column */}
        {mobile ? (
          <td>
            {checkedArray.map((item, index) => {
              return (
                <div key={index} className="d-flex">
                  {item.checked ? <GreenCheckmarkMobile /> : <MinusIconMobile />}
                  <span dangerouslySetInnerHTML={{ __html: item.productName }} />
                </div>
              );
            })}
          </td>
        ) : (
          checkedArray.map((item, index) => {
            return (
              <td className="text-center" key={index}>
                {item.checked ? <GreenCheckmark /> : <MinusIcon />}
              </td>
            );
          })
        )}
      </tr>
    );
  };

  const DesktopOpenAccountCTA = (prop) => (
    <Link className={`btn btn-block d-none d-xl-block ${prop.btnClass}`} id={prop.id} to="/open-an-account">
      Abrir una cuenta
    </Link>
  );

  const ShowHideFeaturesButton = (prop) => (
    <a
      className={prop.class}
      href="/#"
      type="button"
      onClick={(e) => {
        e.preventDefault();
        setHideSimilar(!hideSimilar);
      }}
    >
      {hideSimilar ? "Muestra similitudes" : "Oculta similitudes"}{" "}
      <Icon name={hideSimilar ? "eye" : "eye-slash"} class="mt-1 ml-1" />
    </a>
  );

  return (
    <section id="checking-overview-section" className="container">
      <div className="d-none d-lg-block">
        <h2 className="text-success">Compara cuentas corrientes y abre una cuenta en línea hoy mismo</h2>
        <span className="sr-only">Compara cuentas corrientes y abre una cuenta en línea hoy mismo</span>
        <Link className="btn btn-primary d-xl-none mb-4" id="open-account-cta-lg" to="/open-an-account">
          Abrir una cuenta
        </Link>
        {/* COMPARE CHECKING ACCOUNTS TABLE */}
        <table id="checking-desktop-overview-table mb-0" className={`table table-borderless `}>
          <thead
            className={`bg-success ${styles.stickyRow} ${
              props.isSimpleHeader ? styles.stickyHeaderSimple : styles.stickyHeader
            } `}
          >
            <tr className="text-center">
              <th className="align-bottom">
                {/* Hide Similar Features */}
                <ShowHideFeaturesButton class="btn btn-light px-0" />
              </th>
              <th id="table-header-interest-checking" style={{ height: "1px" }}>
                <div className="d-flex flex-column h-100 justify-content-between">
                  <h4 id="interest-checking-header">
                    <Link
                      to="/es/banca-personal/interest-rewards-checking"
                      id="interest-checking-learn-more"
                      className="text-decoration-none text-white"
                    >
                      Interest Checking
                      <Icon name="arrow-right" class="ml-1" />
                    </Link>
                  </h4>
                  <DesktopOpenAccountCTA id="interest-checking-open-account-cta" btnClass="btn-white" />
                </div>
              </th>
              <th id="table-header-premium-rewards-checking" style={{ height: "1px" }}>
                <div className="d-flex flex-column h-100 justify-content-between">
                  <h4 id="premium-rewards-checking-header">
                    <Link
                      to="/es/banca-personal/rewards-checking"
                      className="text-decoration-none text-white"
                      id="premium-rewards-checking-learn-more"
                    >
                      Premium Rewards Checking
                      <Icon name="arrow-right" class="ml-1" />
                    </Link>
                  </h4>
                  <DesktopOpenAccountCTA id="premium-rewards-checking-open-account-cta" btnClass="btn-white" />
                </div>
              </th>
              <th id="table-header-rewards-checking" style={{ height: "1px" }}>
                <div className="d-flex flex-column h-100 justify-content-between">
                  <h4 id="rewards-checking-header">
                    <Link
                      to="/es/banca-personal/rewards-checking"
                      className="text-decoration-none text-white"
                      id="rewards-checking-learn-more"
                    >
                      Rewards Checking
                      <Icon name="arrow-right" class="ml-1" />
                    </Link>
                  </h4>
                  <DesktopOpenAccountCTA id="rewards-checking-open-account-cta" btnClass="btn-white" />
                </div>
              </th>
              <th id="table-header-free-checking" style={{ height: "1px" }}>
                <div className="d-flex flex-column h-100 justify-content-between">
                  <h4 id="free-checking-header">
                    <Link
                      to="/personal-banking/free-checking-account"
                      className="text-decoration-none text-white"
                      id="free-checking-learn-more"
                    >
                      {props.asterisk1}Free Checking <Icon name="arrow-right" class="ml-1" />
                    </Link>
                  </h4>
                  <DesktopOpenAccountCTA id="free-checking-open-account-cta" btnClass="btn-white" />
                </div>
              </th>
            </tr>
          </thead>
          <tbody className={`${styles.reverseStriped}`}>
            <CheckingRow
              title="Solo $25 de depósito inicial"
              checkedArray={[
                { checked: true, productName: "Interest Checking" },
                { checked: true, productName: "Premium Rewards Checking" },
                { checked: true, productName: "Rewards Checking" },
                { checked: true, productName: "*Free Checking" }
              ]}
            />
            <tr>
              <td className="font-weight-bold">Cargo por servicio</td>
              <td className="text-center">
                <div>
                  <span className="text-success font-weight-bold">$12 por mes</span>
                  <br />
                  Cargo exonerado con un saldo promedio diario de $5,000 o $50,000+ en cuentas elegibles
                </div>
              </td>
              <td className="font-weight-bold text-success text-center">$9 al mes</td>
              <td className="font-weight-bold text-success text-center">$6 al mes</td>
              <td className="font-weight-bold text-success text-center">Gratuito</td>
            </tr>
            <tr>
              <td className="font-weight-bold">Requisito de saldo mínimo</td>
              <td className="text-center">
                <div className="text-success font-weight-bold">$5,000</div>
                <Link to="/es/banca-personal/tasas-de-interes#interest-checking" id="interest-checking-rates-link">
                  Más detalles
                </Link>
              </td>
              <td className="text-center text-success font-weight-bold">$0</td>
              <td className="text-center text-success font-weight-bold">$0</td>
              <td className="text-center text-success font-weight-bold">$0</td>
            </tr>
            <CheckingRow
              title="Gana Intereses"
              url="/es/banca-personal/tasas-de-interes#interest-checking"
              checkedArray={[
                { checked: true, productName: "Interest Checking" },
                { checked: false, productName: "Premium Rewards Checking" },
                { checked: false, productName: "Rewards Checking" },
                { checked: false, productName: "*Free Checking" }
              ]}
            />
            <CheckingRow
              title="Cheques gratis"
              text="de marca WaFd"
              checkedArray={[
                { checked: true, productName: "Interest Checking" },
                { checked: false, productName: "Premium Rewards Checking" },
                { checked: false, productName: "Rewards Checking" },
                { checked: false, productName: "*Free Checking" }
              ]}
            />
            <CheckingRow
              title="Caja de seguridad gratuita de 3X5"
              checkedArray={[
                { checked: true, productName: "Interest Checking" },
                { checked: false, productName: "Premium Rewards Checking" },
                { checked: false, productName: "Rewards Checking" },
                { checked: false, productName: "*Free Checking" }
              ]}
            />
            <tr>
              <td>
                <div>
                  <Link to="/es/banca-personal/rewards-checking" id="checking-show-row" className="font-weight-bold">
                    Checking Rewards
                  </Link>
                </div>
                <div>
                  <CheckingButton
                    id="checking-rewards-toggle"
                    onClick={() => {
                      setCollapseClass2(!collapseClass2);
                    }}
                    isShowText={true}
                    collapseVariable={collapseClass2}
                  />
                </div>
              </td>
              <td className="text-center">
                <GreenCheckmark />
              </td>
              <td className="text-center">
                <GreenCheckmark />
              </td>
              <td className="text-center">
                <GreenCheckmark />
              </td>
              <td className="text-center">
                <MinusIcon />
              </td>
            </tr>
            {collapseClass2 && (
              <>
                <CheckingRow
                  title="5c/gal de descuento con gasolina de Shell"
                  checkedArray={[
                    { checked: true, productName: "Interest Checking" },
                    { checked: true, productName: "Premium Rewards Checking" },
                    { checked: true, productName: "Rewards Checking" },
                    { checked: false, productName: "*Free Checking" }
                  ]}
                  indentation={true}
                />
                <CheckingRow
                  title="Servicios de monitoreo de crédito y resolución de robo de identidad"
                  checkedArray={[
                    { checked: true, productName: "Interest Checking" },
                    { checked: true, productName: "Premium Rewards Checking" },
                    { checked: true, productName: "Rewards Checking" },
                    { checked: false, productName: "*Free Checking" }
                  ]}
                  indentation={true}
                />
                <tr>
                  <td className="font-weight-bold ml-3 d-flex flex-column">Reembolso por robo de identidad</td>
                  <td>
                    Hasta <span className="text-success font-weight-bold">$1 millón</span> en reembolso por su robo de
                    identidad
                  </td>
                  <td>
                    Hasta <span className="text-success font-weight-bold">$1 millón</span> en reembolso por su robo de
                    identidad
                  </td>
                  <td>
                    Hasta <span className="text-success font-weight-bold">$25,000</span> en reembolso por su robo de
                    identidad
                  </td>
                  <td className="text-center">
                    <MinusIcon />
                  </td>
                </tr>
                <tr>
                  <td className="font-weight-bold ml-3 d-flex flex-column">Protección de teléfonos celulares</td>
                  <td>
                    Hasta <span className="text-success font-weight-bold">$800</span> 800 por reclamo de protección para
                    teléfonos celulares
                  </td>
                  <td>
                    Hasta <span className="text-success font-weight-bold">$800</span> 800 por reclamo de protección para
                    teléfonos celulares
                  </td>
                  <td>
                    Hasta <span className="text-success font-weight-bold">$500</span> 800 por reclamo de protección para
                    teléfonos celulares
                  </td>
                  <td className="text-center">
                    <MinusIcon />
                  </td>
                </tr>
                <CheckingRow
                  title="Protección de comprador/garantía extendida"
                  checkedArray={[
                    { checked: true, productName: "Interest Checking" },
                    { checked: true, productName: "Premium Rewards Checking" },
                    { checked: true, productName: "Rewards Checking" },
                    { checked: false, productName: "*Free Checking" }
                  ]}
                  indentation={true}
                />
                <CheckingRow
                  title="Seguro contra Muerte Accidental y Desmembramiento (AD&D)"
                  checkedArray={[
                    { checked: true, productName: "Interest Checking" },
                    { checked: true, productName: "Premium Rewards Checking" },
                    { checked: true, productName: "Rewards Checking" },
                    { checked: false, productName: "*Free Checking" }
                  ]}
                  indentation={true}
                />
                <CheckingRow
                  title="Descuentos de compras"
                  checkedArray={[
                    { checked: true, productName: "Interest Checking" },
                    { checked: true, productName: "Premium Rewards Checking" },
                    { checked: true, productName: "Rewards Checking" },
                    { checked: false, productName: "*Free Checking" }
                  ]}
                  indentation={true}
                />
                <CheckingRow
                  title="Descuentos de servicio de salud"
                  checkedArray={[
                    { checked: true, productName: "Interest Checking" },
                    { checked: true, productName: "Premium Rewards Checking" },
                    { checked: true, productName: "Rewards Checking" },
                    { checked: false, productName: "*Free Checking" }
                  ]}
                  indentation={true}
                />
                <CheckingRow
                  title="Descuentos para viajes y esparcimiento"
                  checkedArray={[
                    { checked: true, productName: "Interest Checking" },
                    { checked: true, productName: "Premium Rewards Checking" },
                    { checked: true, productName: "Rewards Checking" },
                    { checked: false, productName: "*Free Checking" }
                  ]}
                  indentation={true}
                />
                <CheckingRow
                  title="Monitoreo de Dark Web"
                  checkedArray={[
                    { checked: true, productName: "Interest Checking" },
                    { checked: true, productName: "Premium Rewards Checking" },
                    { checked: false, productName: "Rewards Checking" },
                    { checked: false, productName: "*Free Checking" }
                  ]}
                  indentation={true}
                />
                <CheckingRow
                  title="24/7 Asistencia en carretera"
                  checkedArray={[
                    { checked: true, productName: "Interest Checking" },
                    { checked: true, productName: "Premium Rewards Checking" },
                    { checked: false, productName: "Rewards Checking" },
                    { checked: false, productName: "*Free Checking" }
                  ]}
                  indentation={true}
                />
                <CheckingRow
                  title="Protección para servicios de transporte compartido"
                  checkedArray={[
                    { checked: true, productName: "Interest Checking" },
                    { checked: true, productName: "Premium Rewards Checking" },
                    { checked: false, productName: "Rewards Checking" },
                    { checked: false, productName: "*Free Checking" }
                  ]}
                  indentation={true}
                />
                <CheckingRow
                  title="24/7 Servicio de telesalud"
                  checkedArray={[
                    { checked: true, productName: "Interest Checking" },
                    { checked: true, productName: "Premium Rewards Checking" },
                    { checked: false, productName: "Rewards Checking" },
                    { checked: false, productName: "*Free Checking" }
                  ]}
                  indentation={true}
                />
              </>
            )}

            <CheckingRow
              title="Tarjeta de débito sin contacto"
              checkedArray={[
                { checked: true, productName: "Interest Checking" },
                { checked: true, productName: "Premium Rewards Checking" },
                { checked: true, productName: "Rewards Checking" },
                { checked: true, productName: "*Free Checking" }
              ]}
            />

            <tr className={hideSimilar ? `d-none` : `d-table-row`}>
              <td>
                <div className="font-weight-bold">Banca digital</div>
                <div>
                  <CheckingButton
                    id="digital-banking-toggle"
                    onClick={() => {
                      setCollapseClass1(!collapseClass1);
                    }}
                    isShowText={true}
                    collapseVariable={collapseClass1}
                  />
                </div>
              </td>
              <td className="text-center">
                <GreenCheckmark />
              </td>
              <td className="text-center">
                <GreenCheckmark />
              </td>
              <td className="text-center">
                <GreenCheckmark />
              </td>
              <td className="text-center">
                <GreenCheckmark />
              </td>
            </tr>
            {collapseClass1 && (
              <>
                <CheckingRow
                  title="Transferencias bancarias en línea"
                  text="Todavía no está disponible en la aplicación móvil."
                  checkedArray={[
                    { checked: true, productName: "Interest Checking" },
                    { checked: true, productName: "Premium Rewards Checking" },
                    { checked: true, productName: "Rewards Checking" },
                    { checked: true, productName: "*Free Checking" }
                  ]}
                  indentation={true}
                />
                <CheckingRow
                  title="Administre su tarjeta en línea"
                  checkedArray={[
                    { checked: true, productName: "Interest Checking" },
                    { checked: true, productName: "Premium Rewards Checking" },
                    { checked: true, productName: "Rewards Checking" },
                    { checked: true, productName: "*Free Checking" }
                  ]}
                  indentation={true}
                />
                <CheckingRow
                  title="Mueva dinero"
                  checkedArray={[
                    { checked: true, productName: "Interest Checking" },
                    { checked: true, productName: "Premium Rewards Checking" },
                    { checked: true, productName: "Rewards Checking" },
                    { checked: true, productName: "*Free Checking" }
                  ]}
                  indentation={true}
                />
                <CheckingRow
                  title="Zelle<sup>&reg;</sup>"
                  url="/personal-banking/mobile-banking-app/zelle"
                  id="zelle-page-link"
                  text="Una forma rápida, segura y fácil de enviar dinero."
                  checkedArray={[
                    { checked: true, productName: "Interest Checking" },
                    { checked: true, productName: "Premium Rewards Checking" },
                    { checked: true, productName: "Rewards Checking" },
                    { checked: true, productName: "*Free Checking" }
                  ]}
                  indentation={true}
                />
                <CheckingRow
                  title="Estados de Cuenta en linea"
                  checkedArray={[
                    { checked: true, productName: "Interest Checking" },
                    { checked: true, productName: "Premium Rewards Checking" },
                    { checked: true, productName: "Rewards Checking" },
                    { checked: true, productName: "*Free Checking" }
                  ]}
                  indentation={true}
                />
              </>
            )}

            <CheckingRow
              title="Start Savings"
              url="/es/banca-personal/cuentas-de-ahorro"
              checkedArray={[
                { checked: true, productName: "Interest Checking" },
                { checked: true, productName: "Premium Rewards Checking" },
                { checked: true, productName: "Rewards Checking" },
                { checked: true, productName: "*Free Checking" }
              ]}
            />
            <CheckingRow
              title="Protección contra sobregiros gratuita"
              text="Conecta tu cuenta de ahorros o cheques para protección contra sobregiros."
              url="/personal-banking/checking-account/overdraft-protection"
              checkedArray={[
                { checked: true, productName: "Interest Checking" },
                { checked: true, productName: "Premium Rewards Checking" },
                { checked: true, productName: "Rewards Checking" },
                { checked: true, productName: "*Free Checking" }
              ]}
            />
            <CheckingRow
              title="Línea de crédito para sobregiros"
              text="Sujeto a aprobación de crédito."
              url="/personal-banking/checking-account/overdraft-protection"
              checkedArray={[
                { checked: true, productName: "Interest Checking" },
                { checked: true, productName: "Premium Rewards Checking" },
                { checked: true, productName: "Rewards Checking" },
                { checked: true, productName: "*Free Checking" }
              ]}
            />

            <CheckingRow
              title="Límite diario de cargos NSF"
              text="3 al día o $90."
              checkedArray={[
                { checked: true, productName: "Interest Checking" },
                { checked: true, productName: "Premium Rewards Checking" },
                { checked: true, productName: "Rewards Checking" },
                { checked: true, productName: "*Free Checking" }
              ]}
            />
            <tr className="bg-white border-top d-none d-xl-table-row">
              <td></td>
              <td>
                <DesktopOpenAccountCTA id="interest-checking-open-account-cta-2" btnClass="btn-primary mt-3" />
              </td>
              <td>
                <DesktopOpenAccountCTA id="premium-rewards-checking-open-account-cta-2" btnClass="btn-primary mt-3" />
              </td>
              <td>
                <DesktopOpenAccountCTA id="rewards-checking-open-account-cta-2" btnClass="btn-primary mt-3" />
              </td>
              <td>
                <DesktopOpenAccountCTA id="free-checking-open-account-cta-2" btnClass="btn-primary mt-3" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="d-lg-none">
        <table className={`table table-borderless`}>
          <thead
            className={`bg-white ${styles.stickyRow} ${
              props.isSimpleHeader ? styles.stickyHeaderSimple : styles.stickyHeader
            } `}
          >
            <tr>
              <th colspan="2">
                <h2 className="text-success">Compara Cuentas Corrientes</h2>
                <div className="mb-3">
                  <Link
                    className="btn btn-primary w-100 w-sm-50 w-md-auto"
                    id="mobile-open-account-cta"
                    to="/open-an-account"
                  >
                    Abrir una cuenta
                  </Link>
                </div>
                <ShowHideFeaturesButton class="btn btn-link w-100 w-sm-50 w-md-auto" />
              </th>
            </tr>
          </thead>
          <tbody className={`text-sm ${styles.mobileTdAlign} ${styles.reverseStriped}`}>
            <CheckingRow
              title="Solo $25 de depósito inicial"
              checkedArray={[
                { checked: true, productName: "Interest Checking" },
                { checked: true, productName: "Premium Rewards Checking" },
                { checked: true, productName: "Rewards Checking" },
                { checked: true, productName: "*Free Checking" }
              ]}
              mobile
            />
            <tr>
              <td className="font-weight-bold" style={{ width: "35%" }}>
                Cargo por servicio
              </td>
              <td style={{ width: "65%" }}>
                <ul className="no-padding-inline">
                  <li>
                    <div>
                      Interest Checking <span className="text-success font-weight-bold">$12 por mes</span> Cargo
                      exonerado con un saldo promedio diario de $5,000 o $50,000+ en cuentas elegibles
                    </div>
                  </li>
                  <li>
                    Premium Rewards Checking{" "}
                    <span className="font-weight-bold text-success text-center">$9 al mes</span>
                  </li>
                  <li>
                    Rewards Checking <span className="font-weight-bold text-success text-center">$6 al mes</span>
                  </li>
                  <li>
                    *Free Checking <span className="font-weight-bold text-success text-center">Gratuito</span>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td className="font-weight-bold">Requisito de saldo mínimo</td>
              <td>
                <ul className="no-padding-inline">
                  <li>
                    Interest Checking <span className="text-success font-weight-bold">$5,000</span>{" "}
                    <Link to="/es/banca-personal/tasas-de-interes#interest-checking" id="interest-checking-rates-link">
                      Más detalles
                    </Link>
                  </li>
                  <li>
                    Premium Rewards Checking <span className="text-center text-success font-weight-bold">$0</span>
                  </li>
                  <li>
                    Rewards Checking <span className="text-center text-success font-weight-bold">$0</span>
                  </li>
                  <li>
                    *Free Checking <span className="text-center text-success font-weight-bold">$0</span>
                  </li>
                </ul>
              </td>
            </tr>
            <CheckingRow
              title="Gana Intereses"
              url="/es/banca-personal/tasas-de-interes#interest-checking"
              checkedArray={[
                { checked: true, productName: "Interest Checking" },
                { checked: false, productName: "Premium Rewards Checking" },
                { checked: false, productName: "Rewards Checking" },
                { checked: false, productName: "*Free Checking" }
              ]}
              mobile
            />
            <CheckingRow
              title="Cheques gratis"
              text="de marca WaFd"
              mobile
              checkedArray={[
                { checked: true, productName: "Interest Checking" },
                { checked: false, productName: "Premium Rewards Checking" },
                { checked: false, productName: "Rewards Checking" },
                { checked: false, productName: "*Free Checking" }
              ]}
            />
            <CheckingRow
              title="Caja de seguridad gratuita de 3X5"
              checkedArray={[
                { checked: true, productName: "Interest Checking" },
                { checked: false, productName: "Premium Rewards Checking" },
                { checked: false, productName: "Rewards Checking" },
                { checked: false, productName: "*Free Checking" }
              ]}
              mobile
            />
            <tr>
              <td>
                <div>
                  <Link to="/es/banca-personal/rewards-checking" id="checking-show-row" className="font-weight-bold">
                    Checking Rewards
                  </Link>
                </div>
                <div>
                  <CheckingButton
                    id="checking-rewards-toggle"
                    onClick={() => {
                      setCollapseClass2(!collapseClass2);
                    }}
                    isShowText={true}
                    collapseVariable={collapseClass2}
                  />
                </div>
              </td>
              <td>
                <div className="d-flex">
                  <GreenCheckmarkMobile /> Interest Checking
                </div>
                <div className="d-flex">
                  <GreenCheckmarkMobile /> Premium Rewards Checking
                </div>
                <div className="d-flex">
                  <GreenCheckmarkMobile /> Rewards Checking
                </div>
                <div className="d-flex">
                  <MinusIconMobile /> *Free Checking
                </div>
              </td>
            </tr>
            {collapseClass2 && (
              <>
                <CheckingRow
                  title="5c/gal de descuento con gasolina de Shell"
                  checkedArray={[
                    { checked: true, productName: "Interest Checking" },
                    { checked: true, productName: "Premium Rewards Checking" },
                    { checked: true, productName: "Rewards Checking" },
                    { checked: false, productName: "*Free Checking" }
                  ]}
                  indentation={true}
                  mobile
                />
                <CheckingRow
                  title="Servicios de monitoreo de crédito y resolución de robo de identidad."
                  checkedArray={[
                    { checked: true, productName: "Interest Checking" },
                    { checked: true, productName: "Premium Rewards Checking" },
                    { checked: true, productName: "Rewards Checking" },
                    { checked: false, productName: "*Free Checking" }
                  ]}
                  indentation={true}
                  mobile
                />
                <tr>
                  <td className="font-weight-bold ml-3 d-flex flex-column">Reembolso por robo de identidad</td>
                  <td>
                    <div className="d-flex">
                      <GreenCheckmarkMobile />{" "}
                      <div>
                        Interest Checking Hasta <span className="text-success font-weight-bold">$1 million</span> en
                        reembolso por su robo de identidad
                      </div>
                    </div>
                    <div className="d-flex">
                      <GreenCheckmarkMobile />{" "}
                      <div>
                        Premium Rewards Checking Hasta <span className="text-success font-weight-bold">$1 million</span>{" "}
                        en reembolso por su robo de identidad
                      </div>
                    </div>
                    <div className="d-flex">
                      <GreenCheckmarkMobile />{" "}
                      <div>
                        Rewards Checking Hasta <span className="text-success font-weight-bold">$25,000</span> en
                        reembolso por su robo de identidad
                      </div>
                    </div>
                    <div className="d-flex">
                      <MinusIconMobile /> <div>*Free Checking</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="font-weight-bold ml-3 d-flex flex-column">Protección de teléfonos celulares</td>
                  <td>
                    <div className="d-flex">
                      <GreenCheckmarkMobile />{" "}
                      <div>
                        Interest Checking Hasta <span className="text-success font-weight-bold">$800</span> por reclamo
                        de protección para teléfonos celulares
                      </div>
                    </div>
                    <div className="d-flex">
                      <GreenCheckmarkMobile />{" "}
                      <div>
                        Premium Rewards Checking Hasta <span className="text-success font-weight-bold">$800</span> 800
                        por reclamo de protección para teléfonos celulares
                      </div>
                    </div>
                    <div className="d-flex">
                      <GreenCheckmarkMobile />{" "}
                      <div>
                        Rewards Checking Hasta <span className="text-success font-weight-bold">$500</span> por reclamo
                        de protección para teléfonos celulares
                      </div>
                    </div>
                    <div className="d-flex">
                      <MinusIconMobile /> <div>*Free Checking</div>
                    </div>
                  </td>
                </tr>
                <CheckingRow
                  title="Protección de comprador/garantía extendida"
                  checkedArray={[
                    { checked: true, productName: "Interest Checking" },
                    { checked: true, productName: "Premium Rewards Checking" },
                    { checked: true, productName: "Rewards Checking" },
                    { checked: false, productName: "*Free Checking" }
                  ]}
                  indentation={true}
                  mobile
                />
                <CheckingRow
                  title="Seguro contra Muerte Accidental y Desmembramiento (AD&D)"
                  checkedArray={[
                    { checked: true, productName: "Interest Checking" },
                    { checked: true, productName: "Premium Rewards Checking" },
                    { checked: true, productName: "Rewards Checking" },
                    { checked: false, productName: "*Free Checking" }
                  ]}
                  indentation={true}
                  mobile
                />
                <CheckingRow
                  title="Descuentos de compras"
                  checkedArray={[
                    { checked: true, productName: "Interest Checking" },
                    { checked: true, productName: "Premium Rewards Checking" },
                    { checked: true, productName: "Rewards Checking" },
                    { checked: false, productName: "*Free Checking" }
                  ]}
                  indentation={true}
                  mobile
                />
                <CheckingRow
                  title="Descuentos de servicio de salud"
                  checkedArray={[
                    { checked: true, productName: "Interest Checking" },
                    { checked: true, productName: "Premium Rewards Checking" },
                    { checked: true, productName: "Rewards Checking" },
                    { checked: false, productName: "*Free Checking" }
                  ]}
                  indentation={true}
                  mobile
                />
                <CheckingRow
                  title="Descuentos para viajes y esparcimiento"
                  checkedArray={[
                    { checked: true, productName: "Interest Checking" },
                    { checked: true, productName: "Premium Rewards Checking" },
                    { checked: true, productName: "Rewards Checking" },
                    { checked: false, productName: "*Free Checking" }
                  ]}
                  indentation={true}
                  mobile
                />
                <CheckingRow
                  title="Monitoreo de Dark Web"
                  checkedArray={[
                    { checked: true, productName: "Interest Checking" },
                    { checked: true, productName: "Premium Rewards Checking" },
                    { checked: false, productName: "Rewards Checking" },
                    { checked: false, productName: "*Free Checking" }
                  ]}
                  indentation={true}
                  mobile
                />
                <CheckingRow
                  title="24/7 Asistencia en carretera"
                  checkedArray={[
                    { checked: true, productName: "Interest Checking" },
                    { checked: true, productName: "Premium Rewards Checking" },
                    { checked: false, productName: "Rewards Checking" },
                    { checked: false, productName: "*Free Checking" }
                  ]}
                  indentation={true}
                  mobile
                />
                <CheckingRow
                  title="Protección para servicios de transporte compartido"
                  checkedArray={[
                    { checked: true, productName: "Interest Checking" },
                    { checked: true, productName: "Premium Rewards Checking" },
                    { checked: false, productName: "Rewards Checking" },
                    { checked: false, productName: "*Free Checking" }
                  ]}
                  indentation={true}
                  mobile
                />
                <CheckingRow
                  title="24/7 Servicio de telesalud"
                  checkedArray={[
                    { checked: true, productName: "Interest Checking" },
                    { checked: true, productName: "Premium Rewards Checking" },
                    { checked: false, productName: "Rewards Checking" },
                    { checked: false, productName: "*Free Checking" }
                  ]}
                  indentation={true}
                  mobile
                />
              </>
            )}
            <CheckingRow
              title="Tarjeta de débito sin contacto"
              checkedArray={[
                { checked: true, productName: "Interest Checking" },
                { checked: true, productName: "Premium Rewards Checking" },
                { checked: true, productName: "Rewards Checking" },
                { checked: true, productName: "*Free Checking" }
              ]}
              mobile
            />
            <tr className={hideSimilar ? `d-none` : `d-table-row`}>
              <td>
                <div className="font-weight-bold">Banca digital</div>
                <div>
                  <CheckingButton
                    id="digital-banking-toggle"
                    onClick={() => {
                      setCollapseClass1(!collapseClass1);
                    }}
                    isShowText={true}
                    collapseVariable={collapseClass1}
                  />
                </div>
              </td>
              <td>
                <div className="d-flex">
                  <GreenCheckmarkMobile /> Interest Checking
                </div>
                <div className="d-flex">
                  <GreenCheckmarkMobile /> Premium Rewards Checking
                </div>
                <div className="d-flex">
                  <GreenCheckmarkMobile /> Rewards Checking
                </div>
                <div className="d-flex">
                  <GreenCheckmarkMobile /> *Free Checking
                </div>
              </td>
            </tr>
            {collapseClass1 && (
              <>
                <CheckingRow
                  title="Transferencias bancarias en línea"
                  text="Todavía no está disponible en la aplicación móvil."
                  checkedArray={[
                    { checked: true, productName: "Interest Checking" },
                    { checked: true, productName: "Premium Rewards Checking" },
                    { checked: true, productName: "Rewards Checking" },
                    { checked: true, productName: "*Free Checking" }
                  ]}
                  indentation={true}
                  mobile
                />
                <CheckingRow
                  title="Administre su tarjeta en línea"
                  checkedArray={[
                    { checked: true, productName: "Interest Checking" },
                    { checked: true, productName: "Premium Rewards Checking" },
                    { checked: true, productName: "Rewards Checking" },
                    { checked: true, productName: "*Free Checking" }
                  ]}
                  indentation={true}
                  mobile
                />
                <CheckingRow
                  title="Mueva dinero"
                  checkedArray={[
                    { checked: true, productName: "Interest Checking" },
                    { checked: true, productName: "Premium Rewards Checking" },
                    { checked: true, productName: "Rewards Checking" },
                    { checked: true, productName: "*Free Checking" }
                  ]}
                  indentation={true}
                  mobile
                />
                <CheckingRow
                  title="Zelle<sup>&reg;</sup>"
                  url="/personal-banking/mobile-banking-app/zelle"
                  id="zelle-page-link"
                  text="Una forma rápida, segura y fácil de enviar dinero."
                  checkedArray={[
                    { checked: true, productName: "Interest Checking" },
                    { checked: true, productName: "Premium Rewards Checking" },
                    { checked: true, productName: "Rewards Checking" },
                    { checked: true, productName: "*Free Checking" }
                  ]}
                  indentation={true}
                  mobile
                />
                <CheckingRow
                  title="Estados de Cuenta en linea"
                  checkedArray={[
                    { checked: true, productName: "Interest Checking" },
                    { checked: true, productName: "Premium Rewards Checking" },
                    { checked: true, productName: "Rewards Checking" },
                    { checked: true, productName: "*Free Checking" }
                  ]}
                  indentation={true}
                  mobile
                />
              </>
            )}
            <CheckingRow
              title="Start Savings"
              url="/es/banca-personal/cuentas-de-ahorro"
              checkedArray={[
                { checked: true, productName: "Interest Checking" },
                { checked: true, productName: "Premium Rewards Checking" },
                { checked: true, productName: "Rewards Checking" },
                { checked: true, productName: "*Free Checking" }
              ]}
              mobile
            />
            <CheckingRow
              title="Protección contra sobregiros gratuita"
              text="Conecta tu cuenta de ahorros o cheques para protección contra sobregiros."
              url="/personal-banking/checking-account/overdraft-protection"
              checkedArray={[
                { checked: true, productName: "Interest Checking" },
                { checked: true, productName: "Premium Rewards Checking" },
                { checked: true, productName: "Rewards Checking" },
                { checked: true, productName: "*Free Checking" }
              ]}
              mobile
            />
            <CheckingRow
              title="Línea de crédito para sobregiros"
              text="Sujeto a aprobación de crédito."
              url="/personal-banking/checking-account/overdraft-protection"
              checkedArray={[
                { checked: true, productName: "Interest Checking" },
                { checked: true, productName: "Premium Rewards Checking" },
                { checked: true, productName: "Rewards Checking" },
                { checked: true, productName: "*Free Checking" }
              ]}
              mobile
            />
            <CheckingRow
              title="Daily Cap of NSF Fees"
              text="3 per day or $90."
              checkedArray={[
                { checked: true, productName: "Interest Checking" },
                { checked: true, productName: "Premium Rewards Checking" },
                { checked: true, productName: "Rewards Checking" },
                { checked: true, productName: "*Free Checking" }
              ]}
              mobile
            />
          </tbody>
        </table>
        <Link className="btn btn-primary w-100 w-sm-auto" id="mobile-open-account-cta-2" to="/open-an-account">
          Abrir una cuenta
        </Link>
      </div>
      <div className="bg-success py-2 my-3 px-1">
        <h5 className="mb-0 text-white text-center">
          Para más información, mira las{" "}
          <Link
            to="/es/banca-personal/preguntas-frecuentes-sobre-los-rewards-de-cuentas-de-cheques"
            id="checking-rewards-faq-link"
            className="text-white"
          >
            Recompensas FAQs
          </Link>
          .
        </h5>
      </div>

      {props.hasFootnotes && (
        <p className="text-muted text-center text-sm mb-0">
          <span id="checking-table-disclaimer-1-lg">
            {props.asterisk1}Se puede aplicar un cargo por fondos insuficientes (NSF).
          </span>
          <br />
          <span id="checking-table-disclaimer-2-lg">
            Algunos beneficios requieren registro y activación dentro de la banca móvil o en línea.
          </span>
        </p>
      )}
    </section>
  );
};
export default CheckingOverviewSpanish;

CheckingOverviewSpanish.defaultProps = {
  asterisk1: "*",
  asterisk2: "",
  mAsterisk1: "*",
  mAsterisk2: "",
  hasFootnotes: true,
  hasMFootnotes: true,
  isSimpleHeader: false
};
